import { useLoaderData, useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { trackInitialBrandSearchClick } from '#app/utils/tracking/amplitude/search-initial-brand-click'
import { trackInitialCategoriesSearchClick } from '#app/utils/tracking/amplitude/search-initial-categories-click'
import { trackInitialFaqSearchClick } from '#app/utils/tracking/amplitude/search-initial-faq-click'
import { trackViewAllBrandsClick } from '#app/utils/tracking/amplitude/search-view-all-brands'
import Body from '../typography/body'
import SearchBrandHStack from './search-brand-hstack'
import SearchInformationCard from './search-infomation-card'
import SearchMarketingCard from './search-marketing-card'

type ImageDetails = {
	id: number
	alt: string
	name: string
	focus: string
	title: string
}

type Column = {
	component: string
	_uid: string
	body: string
	image: ImageDetails
	heading: string
	topBody: string
	linkToPage: string
	textColor: string
}

type Content = {
	body: any[] // Since this doesn't seem relevant for rendering, type as any or further specify if needed
	columns: Column[]
}

type Story = {
	content: Content
}

type StoriesData = {
	stories: Story[]
}

type LoaderData = {
	searchData: {
		data: StoriesData
	}
}

const SearchTrendingContent = () => {
	const { searchData } = useLoaderData<LoaderData>()
	const location = useLocation()
	const currentUrl = location.pathname + location.search
	// Access the first story's content if it exists, otherwise default to an empty array
	const story = searchData.data.stories[1]?.content.body ?? [] // Accessing the first story's body array
	let { t } = useTranslation('search')

	const handleCardClick = (index: number, type: string) => {
		if (type === 'initial_categories_search_click') {
			trackInitialCategoriesSearchClick(index, currentUrl)
		} else if (type === 'initial_faq_search_click') {
			trackInitialFaqSearchClick(index, currentUrl)
		}
	}

	const handleBrandClick = (brand: any) => {
		trackInitialBrandSearchClick(brand.index, currentUrl)
	}

	const handleViewAllBrandsClick = () => {
		trackViewAllBrandsClick(currentUrl)
	}

	return (
		<div className="space-y-3 bg-white">
			<Body as="m" className="text-beige-100">
				{t('search_trending')}
			</Body>
			{story.map((bodyItem: any) => {
				if (bodyItem.component === 'gridColumns') {
					return (
						<div
							key={bodyItem._uid}
							className="grid grid-cols-2 gap-4 overflow-x-auto scrollbar-hide md:grid-cols-4"
							onScroll={e => e.stopPropagation()}
						>
							{bodyItem.columns.map((column: Column, index: number) =>
								column.component === 'searchMarketingCard' ? (
									<div
										key={column._uid}
										className="min-w-[40%] shrink-0 md:min-w-[25%]"
										onClick={() =>
											handleCardClick(index, 'initial_categories_search_click')
										}
									>
										<SearchMarketingCard
											key={column._uid}
											blok={column}
											_uid={''}
											component={'searchMarketingCard'}
										/>
									</div>
								) : (
									<div
										key={column._uid}
										className="min-w-[40%] shrink-0 md:min-w-[25%]"
										onClick={() =>
											handleCardClick(index, 'initial_faq_search_click')
										}
									>
										<SearchInformationCard
											key={column._uid}
											blok={column}
											_uid={''}
											component={'searchInformationCard'}
										/>
									</div>
								),
							)}
						</div>
					)
				} else if (bodyItem.component === 'searchBrandHStack') {
					return (
						<div key={bodyItem._uid}>
							<SearchBrandHStack
								blok={bodyItem}
								_uid={bodyItem._uid}
								component={'searchBrandHStack'}
								handleBrandClick={handleBrandClick}
								handleViewAllBrandsClick={handleViewAllBrandsClick}
							/>
						</div>
					)
				}
				return null
			})}
		</div>
	)
}

export default SearchTrendingContent
