import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { type SearchInformationCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import Body from '../typography/body'
import Heading from '../typography/heading'

const SearchInformationCard = ({ blok }: SearchInformationCardStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative max-h-[226px] max-w-[168px] overflow-hidden rounded-[24px] bg-cover bg-center bg-no-repeat  md:max-h-full md:max-w-full"
			style={{ backgroundImage: `url(${blok.imageUrl})` }}
		>
			<img alt="genderImage" src={blok.image?.filename} fetchpriority="high" />
			<BlackBackgroundColorForImages />

			<div className="absolute bottom-2 left-2  flex flex-col gap-[4px]">
				<Heading
					as="h7"
					className="text-[18px] md:text-[18px]"
					textColor={blok.textColor}
				>
					{blok.heading}
				</Heading>
				<Link to={blok.linkBodyOne}>
					<Body className="underline" as="s" textColor={blok.textColor}>
						{blok.bodyOne}
					</Body>
				</Link>
				<Link to={blok.linkBodyTwo}>
					<Body className="underline" as="s" textColor={blok.textColor}>
						{blok.bodyTwo}
					</Body>
				</Link>
				<Link to={blok.linkBodyThree}>
					<Body className="underline" as="s" textColor={blok.textColor}>
						{blok.bodyThree}
					</Body>
				</Link>
			</div>
		</div>
	)
}

export default SearchInformationCard
