import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'

import { type SearchBrandHStackStoryblok } from '#types/component-types-sb'
import Body from '../typography/body'
import ButtonAndLinks from '../typography/button-and-links'
import { Icon } from '../ui/icon'

const SearchBrandHStack = ({
	blok,
	handleBrandClick,
	handleViewAllBrandsClick,
}: SearchBrandHStackStoryblok & {
	handleBrandClick: (brand: any) => void
	handleViewAllBrandsClick: () => void
}) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex w-full flex-col items-start space-y-3"
		>
			<Body as="m" className="ml-2 text-beige-100">
				{blok.heading}
			</Body>
			<div className="grid grid-cols-2 space-x-4 overflow-x-auto scrollbar-hide md:grid-cols-8">
				{blok.brandLabels.map(
					(label: { filename: string; alt: string }, index: number) => (
						<Link
							key={index}
							onClick={() => handleBrandClick({ label, index })}
							to={`/${label.alt}`}
							className="flex-shrink-0"
						>
							<img
								src={label.filename}
								alt={label.alt || 'Brand image'}
								className="h-auto max-h-20 w-full object-contain"
							/>
						</Link>
					),
				)}
			</div>
			<Link
				to={'/brands'}
				className="flex flex-col "
				onClick={handleViewAllBrandsClick}
			>
				<div className="z-10 flex items-center">
					<ButtonAndLinks className="ml-2" as="s" body={blok.navigationText} />
					<Icon className="text-body-md h-4 w-4" name="right-arrow" />
				</div>
				<div className="-mt-2 rounded-lg border-4 border-beige-50" />
			</Link>
		</div>
	)
}

export default SearchBrandHStack
